<template>
  <div class="horiz-container">
    <div class="j-record">
      <div class="j-player">
        <iframe :src="playLink" allow="autoplay; fullscreen; picture-in-picture; encrypted-media;" frameborder="0" allowfullscreen style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"></iframe>
      </div>
      <div class="w-100">
        <NuxtLink :to="backURL" class="action-btn">Вернуться <i class="icon nicon-external-link"></i></NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { loadScript, hashCode } from '@/helpers/jitsi'
import { getMentiMeet, getMentorMeet } from '@/api/mentoring'

useHead({
  title: 'Запись конференции'
})

const user = inject('user')
const route = useRoute()

const { data: meet } = await useAsyncData(() => (user.value.is_mentor ? getMentiMeet : getMentorMeet)(route.params.meet))

const playLink = computed(() => 'https://kinescope.io/embed/' + meet.value.play_id)
const backURL = computed(() => route.fullPath.substring(0, route.fullPath.length - route.params.meet.length - 8))
</script>

<style scoped>
.j-record {
  padding-top: 10px;
  height: 740px;
  max-height: 80vh;
  max-width: 1366px;
}
.j-player {
  position: relative;
  height: 720px;
  max-height: 80vh;
}
.action-btn {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--color-primary);
  margin: auto;
}
.icon {
  margin-left: 14px;
}
.w-100 {
  width: 100%;
  padding-top: 15px;
  text-align: center;
}
</style>
